import React from "react";
import useLang from "../../contexts/Language/LanguageContext";
import {text} from "./Team.module.scss";
import mima from "../../images/Mimasmall.jpg";
import mimacv from "../../files/MimaCV.pdf";
import mimacven from "../../files/MimaCVen.pdf";
import irena from "../../images/irenasmall.jpg";
import irenacv from "../../files/irenacvmne.pdf";
import irenacven from "../../files/irenacven.pdf";
import translate from "../../utils/lang/langHash";
import brankacv from "../../files/BrankaCVMNE.pdf";
import brankacven from "../../files/BrankaCVEN.pdf";
import branka from "../../images/Brankasquare.jpg";

const team = [
    {
        position: {
            mne: "Izvršni/a direktor/ka",
            en: "Executive director"
        },
        people: [
            {
                name: "Miroslava Mima Ivanovic",
                cvmne: mimacv,
                cven: mimacven,
                image: mima
            }
        ]
    },
    {
        position: {
            mne: "PR menadžer/ka - Saradnica za medije",
            en: "PR Manager - Associate for the media"
        },
        people: [
            {
                name: "Irena Rašović",
                cvmne: irenacv,
                cven: irenacven,
                image: irena
            }
        ]
    },
    {
        position: {
            mne: "Finansijski/a menadžer/ka",
            en: "Financial manager",
        },
        people: [
            {
                name: "Branka Nikčević",
                cvmne: brankacv,
                cven: brankacven,
                image: branka
            }
        ]
    },
    {
        position: {
            mne: "Poslovni/a sekretar/ka",
            en: "Business Secretary",
        },
        people: []
    },
];
const experts = [
    {
        position: {
            mne: "Rukovodilac/teljka stručnog tima",
            en: "Head of the expert team;",
        },
        people: []
    },
    {
        position: {
            mne: "Stručnjaci/kinje iz oblasti značajnih za OSI",
            en: "Experts in areas relevant to PWDs",
        },
        people: []
    },
]

export default function Team() {
    const lang = useLang();
    return (
        <>
            <h1>{lang === "mne" ? "Tim I MI Boke" : "Team IYDB"}</h1>
            <p className={text}>{translate("team1", lang)}</p>
            <p className={text}>{translate("team2", lang)}</p>
            <p className={text}>{translate("team3", lang)}</p>
            <p className={text}>{translate("team4", lang)}</p>
            <p className={text}>{translate("team5", lang)}</p>
            {team.map((t, i) => {
                return (
                    <div key={i} role="list">
                    <p>{lang === "mne" ? t.position.mne : t.position.en}</p>
                    {t.people.map(p => {
                        return (
                            <div role="listitem" style={{display: "flex", alignItems: "center"}}>
                            <img alt={p.name + " " + "slika"} 
                                style={{objectFit: "fill", border: "2px solid #00adee", width: "70px", height: "70px", borderRadius: "200px"}} 
                                src={p.image} 
                            />
                            <div style={{fontSize: "20px", color: "white", marginLeft: "10px", textTransform: "uppercase"}}>
                                <a title="preuzmi cv" href={lang === "mne" ? p.cvmne : p.cven ? p.cven : p.cvmne} download>
                                {p.name}
                                </a>
                            </div>
                            </div>
                        )
                    })}
                    </div>
                )
            })}
            <p className={text}>{translate("team6", lang)}</p>
            <p className={text}>{translate("team7", lang)}</p>
            {experts.map(e => {
                return (
                    <div role="list">
                    <p>{lang === "mne" ? e.position.mne : e.position.en}</p>
                    {e.people.map(p => {
                        return (
                            <div role="listitem" style={{display: "flex", alignItems: "center"}}>
                            <img alt={p.name + " " + "slika"} 
                                style={{objectFit: "fill", border: "2px solid #00adee", width: "70px", height: "70px", borderRadius: "200px"}} 
                                src={p.image} 
                            />
                            <div style={{fontSize: "20px", color: "white", marginLeft: "10px", textTransform: "uppercase"}}>
                                <a title="preuzmi cv" href={lang === "mne" ? p.cvmne : p.cven ? p.cven : p.cvmne} download>
                                {p.name}
                                </a>
                            </div>
                            </div>
                        )
                    })}
                    </div>
                )
            })}
        </>
    )
}